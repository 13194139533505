import $ from "jquery";
import Swiper, { Navigation, Pagination, Autoplay, EffectFade, Controller} from 'swiper';
import 'swiper/css/bundle';

window.$ = $;
window.Swiper = Swiper;

//jQuery equalheight https://github.com/susonwaiba/jquery-equal-height
$.fn.jQueryEqualHeight=function(i){void 0==i&&(i=".card");var t=0,h=0,n=new Array,e=0;$(this).each(function(){if($(this).find(i).height("auto"),e=$(this).position().top,h!=e){for(currentDiv=0;currentDiv<n.length;currentDiv++)n[currentDiv].find(i).height(t);n.length=0,h=e,t=$(this).find(i).height(),n.push($(this))}else n.push($(this)),t=t<$(this).find(i).height()?$(this).find(i).height():t;for(currentDiv=0;currentDiv<n.length;currentDiv++)n[currentDiv].find(i).height(t)})};


// Swiper
const swiperBackground = new Swiper('.slider-background', {
	modules: [Autoplay, EffectFade, Controller],
	allowTouchMove: false,
	autoplay: {
		delay: 5000,
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
});

const swiperForeground = new Swiper('.slider-foreground', {
	modules: [Navigation, Pagination, Autoplay, Controller],
	autoplay: {
		delay: 5000,
	},
	autoHeight: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		renderBullet: function (index, className) {
			return '<div class="' + className + '"><span>' + (index + 1) + "</span></div>";
		},
	},
});

swiperForeground.controller.control = swiperBackground;


$(function () {

	$('.header__top').on('click', 'button', function (e) {

		var toggle = '#' + $(this).attr('id'),
			menu = '.' + $(this).attr('data-menu');

		if ($(menu).hasClass('is-active')) {
			$('#toggle-menu, #toggle-search, .mainmenu, .search').removeClass('is-active');
			$('#toggle-menu, #toggle-search').attr('aria-expanded', 'false');

			this.setAttribute('aria-expanded', 'false');
			$(menu).removeClass('is-active');
			$(toggle).removeClass('is-active');
		} else {
			$('#toggle-menu, #toggle-search, .mainmenu, .search').removeClass('is-active');
			$('#toggle-menu, #toggle-search').attr('aria-expanded', 'false');

			$(toggle).addClass('is-active');
			$(menu).addClass('is-active');
			this.setAttribute('aria-expanded', 'true');
			$('#search').trigger('focus');
		}


	});

	$(".swiper-playpause .pause").on('click', function () {
		swiperBackground.autoplay.stop();
		swiperForeground.autoplay.stop();
		$('.swiper-playpause .pause').removeClass('active');
		$('.swiper-playpause .play').addClass('active');
	});

	$(".swiper-playpause .play").on('click', function () {
		swiperBackground.autoplay.start();
		swiperForeground.autoplay.start();
		$('.swiper-playpause .play').removeClass('active');
		$('.swiper-playpause .pause').addClass('active');
	});

	$('.jQueryEqualHeight').jQueryEqualHeight();

});